import axios from "axios";
import { IComponentFiltersSelected } from "components/ConfigurableFilter/ConfigurableFilter";
import { IFilterClauses } from "components/ConfigurableFilter/ConfigurableFilterTypes";
import { Endpoints } from "config/endpoints";
import { QueryClient } from "react-query";

const queryClient = new QueryClient();

const TIMEOUT_REQUEST = 30000;

interface IFilterService {
  getServiceEndpoint(): string;
  getFilterDataSources(filterCode: string, params?: any): Promise<any>;
  getFilterClauses(
    selectedFilters: IComponentFiltersSelected | undefined,
    cacheKey: string
  ): Promise<IFilterClauses>;
}

export const FilterService: IFilterService = {
  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_FILTER_ENDPOINT");
  },

  getFilterDataSources: async (filterCode: string, params?: any) => {
    const FilterServiceUrl = FilterService.getServiceEndpoint();
    const result = await axios.post(
      `${FilterServiceUrl}filter/filterDataSources/${filterCode}`,
      params ? params : null,
      {
        headers: { "Cache-Control": "min-fresh=36000" },
        timeout: TIMEOUT_REQUEST,
      }
    );
    return result.data;
  },

  getFilterClauses: async (
    selectedFilters: IComponentFiltersSelected | undefined,
    cacheKey: string
  ) => {
    let filterClauses: IFilterClauses = {
      additiveWhereExclude: "",
      additiveWhereInclude: "",
      restrictiveWhere: "",
    };

    if (selectedFilters && selectedFilters.values.length) {
      const filterCacheKey = [cacheKey, selectedFilters.values];
      filterClauses = await queryClient.fetchQuery<IFilterClauses>({
        queryKey: filterCacheKey,
        queryFn: async () => {
          const filterServiceUrl = FilterService.getServiceEndpoint();
          const result = await axios.post<IFilterClauses>(
            `${filterServiceUrl}filter/filterClauses`,
            selectedFilters
          );
          return result.data ?? filterClauses;
        },
        staleTime: 10 * 60 * 1000, // 10 minutes
        cacheTime: 15 * 60 * 1000, // 15 minutes (slight buffer)
      });
    }

    return filterClauses;
  },
};
